import {css} from 'lit';
export const styles = css`@import 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap';
:root {
  /**
  * @tokens Basic Colors
  * @presenter Color
  */
  --op-color-white: hsl(0deg 100% 100%);
  --op-color-black: hsl(0deg 0% 0%);
  /**
  * @tokens Primary Colors
  * @presenter Color
  */
  --op-color-primary-h: 216;
  --op-color-primary-s: 58%;
  --op-color-primary-l: 48%;
  /**
  * @tokens Neutral Colors
  * @presenter Color
  */
  --op-color-neutral-h: var(--op-color-primary-h);
  --op-color-neutral-s: 4%;
  --op-color-neutral-l: var(--op-color-primary-l);
  /**
  * @tokens Alerts Warning Colors
  * @presenter Color
  */
  --op-color-alerts-warning-h: 47;
  --op-color-alerts-warning-s: 100%;
  --op-color-alerts-warning-l: 61%;
  /**
  * @tokens Alerts Danger Colors
  * @presenter Color
  */
  --op-color-alerts-danger-h: 0;
  --op-color-alerts-danger-s: 99%;
  --op-color-alerts-danger-l: 76%;
  /**
  * @tokens Alerts Info Colors
  * @presenter Color
  */
  --op-color-alerts-info-h: 216;
  --op-color-alerts-info-s: 58%;
  --op-color-alerts-info-l: 48%;
  /**
  * @tokens Alerts Notice Colors
  * @presenter Color
  */
  --op-color-alerts-notice-h: 130;
  --op-color-alerts-notice-s: 61%;
  --op-color-alerts-notice-l: 64%;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --op-opacity-none: 0;
  --op-opacity-overlay: 0.2;
  --op-opacity-disabled: 0.4;
  --op-opacity-half: 0.5;
  --op-opacity-full: 1;
  /**
  * @tokens Breakpoints
  * @presenter Spacing
  */
  --op-breakpoint-x-small: 512px;
  --op-breakpoint-small: 768px;
  --op-breakpoint-medium: 1024px;
  --op-breakpoint-large: 1280px;
  --op-breakpoint-x-large: 1440px;
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --op-radius-small: 2px;
  --op-radius-medium: 4px;
  --op-radius-large: 8px;
  --op-radius-x-large: 12px;
  --op-radius-2x-large: 16px;
  --op-radius-circle: 50%;
  --op-radius-pill: 9999px;
  /**
  * @tokens Border Width
  * @presenter Spacing
  */
  --op-border-width: 1px;
  --op-border-width-large: 2px;
  --op-border-width-x-large: 4px;
  /**
  * @tokens Border Color
  * @presenter Color
  */
  --op-color-border: var(--op-color-neutral-plus-five);
  /**
  * @tokens Border Shadow
  * @presenter Shadow
  */
  --op-border-none: 0 0 0 0;
  --op-border-all: 0 0 0 var(--op-border-width);
  --op-border-top: 0 calc(-1 * var(--op-border-width)) 0 0;
  --op-border-right: var(--op-border-width) 0 0 0;
  --op-border-bottom: 0 var(--op-border-width) 0 0;
  --op-border-left: calc(-1 * var(--op-border-width)) 0 0 0;
  --op-border-y: var(--op-border-top) var(--op-color-border), var(--op-border-bottom) var(--op-color-border);
  --op-border-x: var(--op-border-left) var(--op-color-border), var(--op-border-right) var(--op-color-border);
  /**
  * @tokens Font Scale
  * @presenter FontSize
  */
  --op-font-scale-unit: 1rem;
  --op-font-2x-small: calc(var(--op-font-scale-unit) * 1);
  --op-font-x-small: calc(var(--op-font-scale-unit) * 1.2);
  --op-font-small: calc(var(--op-font-scale-unit) * 1.4);
  --op-font-medium: calc(var(--op-font-scale-unit) * 1.6);
  --op-font-large: calc(var(--op-font-scale-unit) * 1.8);
  --op-font-x-large: calc(var(--op-font-scale-unit) * 2);
  --op-font-2x-large: calc(var(--op-font-scale-unit) * 2.4);
  --op-font-3x-large: calc(var(--op-font-scale-unit) * 2.8);
  --op-font-4x-large: calc(var(--op-font-scale-unit) * 3.2);
  --op-font-5x-large: calc(var(--op-font-scale-unit) * 3.6);
  --op-font-6x-large: calc(var(--op-font-scale-unit) * 4.8);
  /**
  * @tokens Font Weight
  * @presenter FontWeight
  */
  --op-font-weight-thin: 100;
  --op-font-weight-extra-light: 200;
  --op-font-weight-light: 300;
  --op-font-weight-normal: 400;
  --op-font-weight-medium: 500;
  --op-font-weight-semi-bold: 600;
  --op-font-weight-bold: 700;
  --op-font-weight-extra-bold: 800;
  --op-font-weight-black: 900;
  /**
  * @tokens Font Family
  * @presenter FontFamily
  */
  --op-font-family: "Noto Sans", "Noto Serif", sans-serif;
  /**
  * @tokens Line Height
  * @presenter LineHeight
  */
  --op-line-height-none: 0;
  --op-line-height-densest: 1;
  --op-line-height-denser: 1.15;
  --op-line-height-dense: 1.3;
  --op-line-height-base: 1.5;
  --op-line-height-loose: 1.6;
  --op-line-height-looser: 1.7;
  --op-line-height-loosest: 1.8;
  /**
  * @tokens Letter Spacing
  * @presenter LetterSpacing
  */
  --op-letter-spacing-navigation: 0.01rem;
  --op-letter-spacing-label: 0.04rem;
  /**
  * @tokens Transition
  */
  --op-transition-accordion: rotate 120ms ease-in;
  --op-transition-input: all 120ms ease-in;
  --op-transition-sidebar: all 200ms ease-in-out;
  --op-transition-modal: all 300ms ease-in;
  --op-transition-panel: right 400ms ease-in;
  --op-transition-tooltip: all 300ms ease-in 300ms;
  /**
  * @tokens Animation
  * @presenter Animation
  */
  --op-animation-flash: rm-slide-in-out-flash 5s normal forwards;
  /**
  * @tokens Encoded Images
  */
  --op-encoded-images-dropdown-arrow-width: calc(1.2 * var(--op-space-scale-unit));
  --op-encoded-images-dropdown-arrow: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02IDguMzc1MDFMMCAyLjM3NTAxTDEuNCAwLjk3NTAwNkw2IDUuNTc1MDFMMTAuNiAwLjk3NTAwNkwxMiAyLjM3NTAxTDYgOC4zNzUwMVoiIGZpbGw9IiMwQTBBMEIiLz4KPC9zdmc+Cg==");
  /**
  * @tokens Size Scale
  * @presenter Spacing
  */
  --op-size-unit: 0.4rem;
  /**
  * @tokens Space Scale
  * @presenter Spacing
  */
  --op-space-scale-unit: 1rem;
  --op-space-3x-small: calc(var(--op-space-scale-unit) * 0.2);
  --op-space-2x-small: calc(var(--op-space-scale-unit) * 0.4);
  --op-space-x-small: calc(var(--op-space-scale-unit) * 0.8);
  --op-space-small: calc(var(--op-space-scale-unit) * 1.2);
  --op-space-medium: calc(var(--op-space-scale-unit) * 1.6);
  --op-space-large: calc(var(--op-space-scale-unit) * 2);
  --op-space-x-large: calc(var(--op-space-scale-unit) * 2.4);
  --op-space-2x-large: calc(var(--op-space-scale-unit) * 2.8);
  --op-space-3x-large: calc(var(--op-space-scale-unit) * 4);
  --op-space-4x-large: calc(var(--op-space-scale-unit) * 8);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --op-shadow-x-small: 0 1px 2px hsl(0deg 0% 0% / 3%), 0 1px 3px hsl(0deg 0% 0% / 15%);
  --op-shadow-small: 0 1px 2px hsl(0deg 0% 0% / 3%), 0 2px 6px hsl(0deg 0% 0% / 15%);
  --op-shadow-medium: 0 4px 8px hsl(0deg 0% 0% / 15%), 0 1px 3px hsl(0deg 0% 0% / 3%);
  --op-shadow-large: 0 6px 10px hsl(0deg 0% 0% / 15%), 0 2px 3px hsl(0deg 0% 0% / 3%);
  --op-shadow-x-large: 0 8px 12px hsl(0deg 0% 0% / 15%), 0 4px 4px hsl(0deg 0% 0% / 3%);
  /**
  * @tokens Z-Index
  */
  --op-z-index-header: 500;
  --op-z-index-footer: 500;
  --op-z-index-drawer: 700;
  --op-z-index-dialog: 800;
  --op-z-index-dialog-backdrop: 801;
  --op-z-index-dialog-content: 802;
  --op-z-index-dropdown: 900;
  --op-z-index-alert-group: 950;
  --op-z-index-tooltip: 1000;
  /**
  * @tokens Input Heights
  * @presenter Spacing
  */
  --op-input-height-small: 2.8rem;
  --op-input-height-medium: 3.6rem;
  --op-input-height-large: 4rem;
  --op-input-height-x-large: 8.4rem;
  /**
  * @tokens Input Focus
  * @presenter Shadow
  */
  --op-input-inner-focus: inset 0 0 0 var(--op-border-width-large);
  --op-input-outer-focus: 0 0 0 var(--op-border-width-x-large);
  --op-input-focus-primary: var(--op-input-inner-focus) var(--op-color-primary-plus-two),
    var(--op-input-outer-focus) var(--op-color-primary-plus-five);
  --op-input-focus-neutral: var(--op-input-inner-focus) var(--op-color-neutral-plus-two),
    var(--op-input-outer-focus) var(--op-color-neutral-plus-five);
  --op-input-focus-danger: var(--op-input-inner-focus) var(--op-color-alerts-danger-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-danger-plus-five);
  --op-input-focus-warning: var(--op-input-inner-focus) var(--op-color-alerts-warning-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-warning-plus-five);
  --op-input-focus-info: var(--op-input-inner-focus) var(--op-color-alerts-info-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-info-plus-five);
  --op-input-focus-notice: var(--op-input-inner-focus) var(--op-color-alerts-notice-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-notice-plus-five);
}

@keyframes rm-slide-in-out-flash {
  0% {
    right: -100%;
  }
  10% {
    right: calc(1rem * 2);
    right: var(--op-space-large);
  }
  70% {
    right: calc(1rem * 2);
    right: var(--op-space-large);
  }
  100% {
    right: -100%;
  }
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar.rangeMode.inline {
  box-shadow: none;
  border: none;
  width: 100%;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer {
  width: 100%;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer .flatpickr-rContainer {
  width: 100%;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer .flatpickr-days {
  width: 100%;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer .flatpickr-days .flatpickr-day {
  max-width: none;
  max-width: initial;
  max-height: none;
  max-height: initial;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer .flatpickr-days .flatpickr-day.inRange {
  box-shadow: none;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-innerContainer .dayContainer {
  width: 100%;
  max-width: none;
  max-width: initial;
  justify-content: flex-start;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "date previous next";
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-month {
  grid-area: date;
  text-align: left;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-month .flatpickr-current-month {
  left: 0;
  text-align: left;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-prev-month {
  grid-area: previous;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-next-month {
  grid-area: next;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-next-month,
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-prev-month {
  position: relative;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: block;
  opacity: 0.5;
}
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-next-month.flatpickr-disabled .btn,
.flatpickr-calendar.rangeMode.inline .flatpickr-months .flatpickr-prev-month.flatpickr-disabled .btn {
  opacity: 0.4;
  opacity: var(--op-opacity-disabled);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.flatpickr-calendar.rangeMode {
  --__flatpickr-default-text-color: var(--op-color-neutral-minus-eight);
  --__flatpickr-range-background: var(--op-color-neutral-plus-six);
  box-shadow: 0px 7px 14px 0px var(--op-color-border);
  border-top: solid 1px var(--op-color-border);
  border-top: solid var(--op-border-width) var(--op-color-border);
  background: var(--op-color-neutral-plus-max);
  color: var(--__flatpickr-default-text-color);
}
.flatpickr-calendar.rangeMode .flatpickr-monthDropdown-months {
  color: var(--__flatpickr-default-text-color);
  font-weight: 500;
  font-weight: var(--op-font-weight-medium);
  line-height: 1.3;
  line-height: var(--op-line-height-dense);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer:not(:first-child) {
  padding-left: calc(1rem * 1.6);
  padding-left: var(--op-space-medium);
  box-shadow: -1px 0 0 var(--op-color-border);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer:not(:last-child) {
  padding-right: calc(1rem * 1.6);
  padding-right: var(--op-space-medium);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day {
  color: var(--op-color-neutral-on-plus-max);
  font-size: calc(1rem * 1.6);
  font-size: var(--op-font-medium);
  font-weight: 600;
  font-weight: var(--op-font-weight-semi-bold);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day:hover {
  border-color: var(--op-color-neutral-plus-five);
  background: var(--op-color-neutral-plus-five);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.flatpickr-disabled {
  color: var(--op-color-neutral-plus-five);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay, .flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay {
  opacity: 0.44;
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.startRange, .flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.endRange, .flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.selected {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gc-btn-primary-background);
  color: var(--gc-btn-primary-text);
  border-radius: var(--gc-radius-default);
  line-height: 1;
  line-height: var(--op-line-height-densest);
  font-weight: 500;
  font-weight: var(--op-font-weight-medium);
  transition: var(--gc-transition-btn-hover);
  font-size: var(--op-font-size-large);
  text-align: center;
  border: none;
  box-shadow: none;
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.startRange:hover, .flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.endRange:hover, .flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.selected:hover {
  background-color: var(--gc-btn-hover-background);
  color: var(--gc-btn-hover-text);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.startRange {
  border-end-end-radius: 0;
  border-start-end-radius: 0;
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.endRange {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--gc-btn-primary-background);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer .flatpickr-day.inRange {
  background: var(--__flatpickr-range-background);
  box-shadow: -5px 0 0 var(--__flatpickr-range-background), 5px 0 0 var(--__flatpickr-range-background);
  border: none;
  color: var(--gc-btn-text);
}
.flatpickr-calendar.rangeMode .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: 5px 0 0 var(--__flatpickr-range-background), 5px 0 0 var(--__flatpickr-range-background);
}
.flatpickr-calendar.rangeMode .flatpickr-weekdays .flatpickr-weekday {
  color: var(--op-color-neutral-plus-three);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  font-weight: var(--op-font-weight-medium);
  line-height: 1.3;
  line-height: var(--op-line-height-dense);
}
.flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-next-month, .flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-prev-month {
  height: auto;
}
.flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-month {
  height: calc(12 * 0.4rem);
  height: calc(12 * var(--op-size-unit));
  display: flex;
  align-items: center;
}
.flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-month .flatpickr-current-month {
  padding-top: 0;
  height: auto;
}
.flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month, .flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-year {
  color: var(--__flatpickr-default-text-color);
  font-weight: 500;
  font-weight: var(--op-font-weight-medium);
  line-height: 1.3;
  line-height: var(--op-line-height-dense);
}
.flatpickr-calendar.rangeMode .shortcut-buttons-flatpickr-wrapper {
  margin-top: calc(1rem * 0.8);
  margin-top: var(--op-space-x-small);
  margin: calc(1rem * 1.6);
  margin: var(--op-space-medium);
}
.flatpickr-calendar.rangeMode .shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons {
  border-top: solid 1px var(--op-color-border);
  border-top: solid var(--op-border-width) var(--op-color-border);
  padding-top: calc(1rem * 1.2);
  padding-top: var(--op-space-small);
  text-align: right;
}
.flatpickr-calendar.rangeMode .btn.btn--small {
  --__op-btn-height: var(--_op-btn-height-small);
  --__op-btn-font-size: var(--_op-btn-font-small);
  --__op-btn-padding: var(--_op-btn-padding-small);
}

.btn-see-previous, .btn-success, .btn-navbar, .btn-warning, .btn-destructive,
.btn-delete, .btn-primary, .btn {
  --_op-btn-height-small: var(--op-input-height-small);
  --_op-btn-height-medium: var(--op-input-height-medium);
  --_op-btn-height-large: var(--op-input-height-large);
  --_op-btn-font-small: var(--op-font-x-small);
  --_op-btn-font-medium: var(--op-font-small);
  --_op-btn-font-large: var(--op-font-small);
  --_op-btn-padding-small: 0 var(--op-space-x-small);
  --_op-btn-padding-medium: 0 var(--op-space-small);
  --_op-btn-padding-large: 0 var(--op-space-small);
  --__op-btn-height: var(--_op-btn-height-large);
  --__op-btn-font-size: var(--_op-btn-font-large);
  --__op-btn-padding: var(--_op-btn-padding-large);
  display: inline-flex;
  min-height: 4rem;
  min-height: var(--__op-btn-height);
  align-items: center;
  justify-content: center;
  padding: 0 calc(1rem * 1.2);
  padding: var(--__op-btn-padding);
  border-radius: 4px;
  border-radius: var(--op-radius-medium);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  font-size: calc(1rem * 1.4);
  font-size: var(--__op-btn-font-size);
  font-weight: 400;
  font-weight: var(--op-font-weight-normal);
  gap: calc(1rem * 0.8);
  gap: var(--op-space-x-small);
  text-align: center;
  text-decoration: none;
  transition: all 120ms ease-in;
  transition: var(--op-transition-input);
  white-space: nowrap;
}
.btn--pill.btn-see-previous, .btn--pill.btn-success, .btn--pill.btn-navbar, .btn--pill.btn-warning, .btn--pill.btn-destructive,
.btn--pill.btn-delete, .btn--pill.btn-primary, .btn--pill.btn {
  border-radius: 9999px;
  border-radius: var(--op-radius-pill);
}
.btn--icon.btn-see-previous, .btn--icon.btn-success, .btn--icon.btn-navbar, .btn--icon.btn-warning, .btn--icon.btn-destructive,
.btn--icon.btn-delete, .btn--icon.btn-primary, .btn--icon.btn {
  width: var(--__op-btn-height);
  min-width: var(--__op-btn-height);
  padding: 0;
}
.btn--icon-with-label.btn-see-previous, .btn--icon-with-label.btn-success, .btn--icon-with-label.btn-navbar, .btn--icon-with-label.btn-warning, .btn--icon-with-label.btn-destructive,
.btn--icon-with-label.btn-delete, .btn--icon-with-label.btn-primary, .btn--icon-with-label.btn {
  flex-direction: column;
  padding: calc(1rem * 1.2);
  padding: var(--op-space-small);
  gap: calc(1rem * 0.2);
  gap: var(--op-space-3x-small);
}
.btn--small.btn-see-previous, .btn--small.btn-success, .btn--small.btn-navbar, .btn--small.btn-warning, .btn--small.btn-destructive,
.btn--small.btn-delete, .btn--small.btn-primary, .btn--small.btn {
  --__op-btn-height: var(--_op-btn-height-small);
  --__op-btn-font-size: var(--_op-btn-font-small);
  --__op-btn-padding: var(--_op-btn-padding-small);
}
.btn--medium.btn-see-previous, .btn--medium.btn-success, .btn--medium.btn-navbar, .btn--medium.btn-warning, .btn--medium.btn-destructive,
.btn--medium.btn-delete, .btn--medium.btn-primary, .btn--medium.btn {
  --__op-btn-height: var(--_op-btn-height-medium);
  --__op-btn-font-size: var(--_op-btn-font-medium);
  --__op-btn-padding: var(--_op-btn-padding-medium);
}
.btn--large.btn-see-previous, .btn--large.btn-success, .btn--large.btn-navbar, .btn--large.btn-warning, .btn--large.btn-destructive,
.btn--large.btn-delete, .btn--large.btn-primary, .btn--large.btn {
  --__op-btn-height: var(--_op-btn-height-large);
  --__op-btn-font-size: var(--_op-btn-font-large);
  --__op-btn-padding: var(--_op-btn-padding-large);
}
.btn--disabled.btn-see-previous, .btn--disabled.btn-success, .btn--disabled.btn-navbar, .btn--disabled.btn-warning, .btn--disabled.btn-destructive,
.btn--disabled.btn-delete, .btn--disabled.btn-primary, .btn--disabled.btn, .btn-see-previous:disabled, .btn-success:disabled, .btn-navbar:disabled, .btn-warning:disabled, .btn-destructive:disabled,
.btn-delete:disabled, .btn-primary:disabled, .btn:disabled {
  opacity: 0.4;
  opacity: var(--op-opacity-disabled);
  pointer-events: none;
  -webkit-user-select: none; /* stylelint-disable property-no-vendor-prefix */
  -moz-user-select: none;
       user-select: none;
}
.btn--with-badge.btn-see-previous, .btn--with-badge.btn-success, .btn--with-badge.btn-navbar, .btn--with-badge.btn-warning, .btn--with-badge.btn-destructive,
.btn--with-badge.btn-delete, .btn--with-badge.btn-primary, .btn--with-badge.btn {
  position: relative;
}
.btn-see-previous:focus, .btn-success:focus, .btn-navbar:focus, .btn-warning:focus, .btn-destructive:focus,
.btn-delete:focus, .btn-primary:focus, .btn:focus, .btn-see-previous:focus-within, .btn-success:focus-within, .btn-navbar:focus-within, .btn-warning:focus-within, .btn-destructive:focus-within,
.btn-delete:focus-within, .btn-primary:focus-within, .btn:focus-within, .btn-see-previous:focus-visible, .btn-success:focus-visible, .btn-navbar:focus-visible, .btn-warning:focus-visible, .btn-destructive:focus-visible,
.btn-delete:focus-visible, .btn-primary:focus-visible, .btn:focus-visible {
  outline: none;
}
@media (max-width: 768px) {
  .btn-see-previous, .btn-success, .btn-navbar, .btn-warning, .btn-destructive,
  .btn-delete, .btn-primary, .btn {
    --__op-btn-height: var(--_op-btn-height-large);
    --__op-btn-font-size: var(--_op-btn-font-large);
  }
  .btn--small.btn-see-previous, .btn--small.btn-success, .btn--small.btn-navbar, .btn--small.btn-warning, .btn--small.btn-destructive,
  .btn--small.btn-delete, .btn--small.btn-primary, .btn--small.btn, .btn--medium.btn-see-previous, .btn--medium.btn-success, .btn--medium.btn-navbar, .btn--medium.btn-warning, .btn--medium.btn-destructive,
  .btn--medium.btn-delete, .btn--medium.btn-primary, .btn--medium.btn, .btn--large.btn-see-previous, .btn--large.btn-success, .btn--large.btn-navbar, .btn--large.btn-warning, .btn--large.btn-destructive,
  .btn--large.btn-delete, .btn--large.btn-primary, .btn--large.btn {
    --__op-btn-height: var(--_op-btn-height-large);
    --__op-btn-font-size: var(--_op-btn-font-large);
    --__op-btn-padding: var(--_op-btn-padding-large);
  }
}

.btn {
  background-color: var(--op-color-neutral-plus-eight);
  box-shadow: inset 0 0 0 1px var(--op-color-neutral-plus-four);
  box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-four);
  color: var(--op-color-neutral-on-plus-eight);
}
.btn.btn--active {
  background-color: var(--op-color-primary-plus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
  color: var(--op-color-primary-on-plus-five);
}
.btn:hover {
  background-color: var(--op-color-primary-plus-eight);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
  color: var(--op-color-primary-on-plus-eight);
}
.btn:focus-visible {
  background-color: var(--op-color-neutral-plus-eight);
  box-shadow: var(--op-input-focus-primary);
  color: var(--op-color-neutral-on-plus-eight);
}
.btn.btn--no-border {
  background-color: transparent;
  box-shadow: none;
  color: var(--op-color-primary-on-plus-max);
}
.btn.btn--no-border.btn--active {
  background-color: var(--op-color-primary-plus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
  color: var(--op-color-primary-on-plus-five);
}
.btn.btn--no-border:hover {
  background-color: var(--op-color-primary-plus-eight);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-five);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-five);
  color: var(--op-color-primary-on-plus-eight);
}
.btn.btn--no-border:focus-visible {
  background-color: var(--op-color-primary-plus-eight);
  box-shadow: var(--op-input-focus-primary);
  color: var(--op-color-primary-on-plus-eight);
}

.btn-primary {
  background-color: var(--op-color-primary-base);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-base);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-base);
  color: var(--op-color-primary-on-base);
}
.btn-primary.btn--active {
  background-color: var(--op-color-primary-minus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-two);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-two);
  color: var(--op-color-primary-on-minus-five);
}
.btn-primary:hover {
  background-color: var(--op-color-primary-plus-one);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-one);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-one);
  color: var(--op-color-primary-on-plus-one);
}
.btn-primary:focus-visible {
  background-color: var(--op-color-primary-base);
  box-shadow: var(--op-input-focus-primary);
  color: var(--op-color-primary-on-base);
}
.btn-primary.btn--no-border {
  background-color: transparent;
  box-shadow: none;
  color: var(--op-color-primary-base);
}
.btn-primary.btn--no-border.btn--active {
  background-color: var(--op-color-primary-plus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
  color: var(--op-color-primary-on-plus-five);
}
.btn-primary.btn--no-border:hover {
  background-color: var(--op-color-primary-plus-eight);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-five);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-five);
  color: var(--op-color-primary-on-plus-eight);
}
.btn-primary.btn--no-border:focus-visible {
  background-color: var(--op-color-primary-plus-eight);
  box-shadow: var(--op-input-focus-primary);
  color: var(--op-color-primary-on-plus-eight);
}

.btn-destructive,
.btn-delete {
  background-color: var(--op-color-alerts-danger-base);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-danger-base);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-base);
  color: var(--op-color-alerts-danger-on-base);
}
.btn-destructive.btn--active,
.btn-delete.btn--active {
  background-color: var(--op-color-alerts-danger-plus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-danger-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-plus-three);
  color: var(--op-color-alerts-danger-on-plus-five);
}
.btn-destructive:hover,
.btn-delete:hover {
  background-color: var(--op-color-alerts-danger-minus-two);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-danger-minus-two);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-minus-two);
  color: var(--op-color-alerts-danger-on-minus-two);
}
.btn-destructive:focus-visible,
.btn-delete:focus-visible {
  background-color: var(--op-color-alerts-danger-base);
  box-shadow: var(--op-input-focus-danger);
  color: var(--op-color-alerts-danger-on-base);
}

.btn-warning {
  background-color: var(--op-color-alerts-warning-base);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-warning-base);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-warning-base);
  color: var(--op-color-alerts-warning-on-base);
}
.btn-warning.btn--active {
  background-color: var(--op-color-alerts-warning-plus-five);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-warning-plus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-warning-plus-three);
  color: var(--op-color-alerts-warning-on-plus-five);
}
.btn-warning:hover {
  background-color: var(--op-color-alerts-warning-minus-two);
  box-shadow: inset 0 0 0 1px var(--op-color-alerts-warning-minus-two);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-warning-minus-two);
  color: var(--op-color-alerts-warning-on-minus-two);
}
.btn-warning:focus-visible {
  background-color: var(--op-color-alerts-warning-base);
  box-shadow: var(--op-input-focus-warning);
  color: var(--op-color-alerts-warning-on-base);
}

.btn-see-previous, .btn-success, .btn-navbar, .btn, .btn-primary, .btn-destructive,
.btn-delete, .btn-warning {
  --_op-btn-padding-small: 0 var(--op-space-small);
  --_op-btn-padding-medium: 0 var(--op-space-medium);
  --_op-btn-padding-large: 0 var(--op-space-medium);
  --_op-btn-font-large: var(--op-font-medium);
  border-radius: var(--gc-radius-default);
  line-height: 1;
  line-height: var(--op-line-height-densest);
  font-weight: 500;
  font-weight: var(--op-font-weight-medium);
  transition: var(--gc-transition-btn-hover);
}
@media screen and (max-width: 512px) {
  .btn--desktop-only.btn-see-previous, .btn--desktop-only.btn-success, .btn--desktop-only.btn-navbar, .btn--desktop-only.btn, .btn--desktop-only.btn-primary, .btn--desktop-only.btn-destructive,
  .btn--desktop-only.btn-delete, .btn--desktop-only.btn-warning {
    --_op-btn-padding-small: 0;
    --_op-btn-padding-medium: 0;
    --_op-btn-padding-large: 0;
    background-color: transparent;
    background-color: initial;
    color: inherit;
    box-shadow: none;
    box-shadow: initial;
    min-height: 0;
    min-height: initial;
  }
}
.btn--bold.btn-see-previous, .btn--bold.btn-success, .btn--bold.btn-navbar, .btn--bold.btn, .btn--bold.btn-primary, .btn--bold.btn-destructive,
.btn--bold.btn-delete, .btn--bold.btn-warning {
  font-weight: 700;
  font-weight: var(--op-font-weight-bold);
}

.btn, .btn:hover:not(:active) {
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: 0 0 0 1px var(--gc-btn-border);
  box-shadow: var(--op-border-all) var(--gc-btn-border);
}
.btn:active {
  background-color: var(--gc-btn-primary-hover-background);
  color: var(--gc-btn-primary-hover-text);
  box-shadow: 0 0 0 1px var(--gc-btn-primary-hover-border);
  box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
}
@media (hover: hover) {
  .btn:hover, .btn:hover:not(:active) {
    background-color: var(--gc-btn-primary-hover-background);
    color: var(--gc-btn-primary-hover-text);
    box-shadow: 0 0 0 1px var(--gc-btn-primary-hover-border);
    box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
  }
}
.btn.btn--no-border, .btn.btn--no-border:hover:not(:active) {
  background-color: var(--gc-btn--no-border-background);
  color: var(--gc-btn--no-border-text);
  box-shadow: inset 0 0 0 1px var(--gc-btn--no-border-border);
  box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-border);
}
.btn.btn--no-border:active {
  background: var(--gc-btn--no-border-hover-background);
  color: var(--gc-btn--no-border-hover-text);
  box-shadow: inset 0 0 0 1px var(--gc-btn--no-border-hover-border);
  box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-hover-border);
}
@media (hover: hover) {
  .btn.btn--no-border:hover, .btn.btn--no-border:hover:not(:active) {
    background: var(--gc-btn--no-border-hover-background);
    color: var(--gc-btn--no-border-hover-text);
    box-shadow: inset 0 0 0 1px var(--gc-btn--no-border-hover-border);
    box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-hover-border);
  }
}
.btn.btn--no-border.btn--active {
  background-color: var(--gc-btn-primary-hover-background);
  color: var(--gc-btn-primary-hover-text);
  box-shadow: 0 0 0 1px var(--gc-btn-primary-hover-border);
  box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
}

.btn-primary, .btn-primary:hover:not(:active) {
  background-color: var(--gc-btn-primary-background);
  color: var(--gc-btn-primary-text);
  box-shadow: 0 0 0 1px var(--gc-btn-primary-border);
  box-shadow: var(--op-border-all) var(--gc-btn-primary-border);
}
.btn-primary:active {
  background-color: var(--gc-btn-primary-hover-background);
  color: var(--gc-btn-primary-hover-text);
  box-shadow: 0 0 0 1px var(--gc-btn-primary-hover-border);
  box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
}
@media (hover: hover) {
  .btn-primary:hover, .btn-primary:hover:not(:active) {
    background-color: var(--gc-btn-primary-hover-background);
    color: var(--gc-btn-primary-hover-text);
    box-shadow: 0 0 0 1px var(--gc-btn-primary-hover-border);
    box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
  }
}
.btn-primary:focus, .btn-primary:focus-within, .btn-primary:focus-visible {
  background-color: var(--gc-btn-focus-background);
  color: var(--gc-btn-focus-text);
  box-shadow: var(--op-input-focus-primary);
}
.btn-primary.btn--no-border {
  color: var(--gc-btn-primary--no-border-text);
}
.btn-primary.btn--no-border.btn--active {
  background-color: var(--gc-btn--no-border-active-background);
  color: var(--gc-btn--no-border-active-text);
  box-shadow: 0 0 0 1px var(--gc-btn--no-border-active-border);
  box-shadow: var(--op-border-all) var(--gc-btn--no-border-active-border);
}
.btn-primary.btn--no-border:hover {
  background-color: var(--gc-btn-primary--no-border-hover-background);
  box-shadow: 0 0 0 1px var(--gc-btn-primary--no-border-hover-border);
  box-shadow: var(--op-border-all) var(--gc-btn-primary--no-border-hover-border);
  color: var(--gc-btn-primary--no-border-hover-text);
}

.btn-delete.btn--no-border {
  background-color: transparent;
  color: var(--op-color-alerts-danger-base);
  box-shadow: none;
}
.btn-delete.btn--no-border:hover {
  color: var(--op-color-alerts-danger-on-base);
  background-color: var(--op-color-alerts-danger-base);
}

.btn-navbar {
  --_op-btn-padding-large: 0 var(--op-space-small);
  --_op-btn-font-large: var(--op-font-medium);
  background-color: transparent;
  color: var(--_op-navbar-text-color);
  fill: var(--_op-navbar-text-color);
  font-weight: 600;
  font-weight: var(--op-font-weight-semi-bold);
  box-shadow: 0 0 0 1px var(--gc-color-navbar-text);
  box-shadow: var(--op-border-all) var(--gc-color-navbar-text);
}
.btn-navbar svg path {
  fill: var(--_op-navbar-text-color);
}
.btn-navbar:hover {
  background-color: transparent;
  color: var(--gc-color-secondary-original);
  fill: var(--gc-color-secondary-original);
  box-shadow: 0 0 0 1px var(--gc-color-secondary-original);
  box-shadow: var(--op-border-all) var(--gc-color-secondary-original);
}
.btn-navbar:hover svg path {
  fill: var(--gc-color-secondary-original);
}
.btn-navbar:focus-visible {
  background-color: var(--gc-btn-focus-background);
  color: var(--gc-btn-focus-text);
  fill: var(--gc-btn-focus-text);
  box-shadow: var(--op-input-focus-primary);
}
.btn-navbar.btn--active {
  background-color: transparent;
  color: var(--gc-color-secondary-original);
  fill: var(--gc-color-secondary-original);
  box-shadow: none;
}
.btn-navbar.btn--active:focus-visible {
  background-color: var(--gc-btn-focus-background);
  color: var(--gc-btn-focus-text);
  fill: var(--gc-btn-focus-text);
  box-shadow: var(--op-input-focus-primary);
}
.btn-navbar.btn--no-border {
  box-shadow: none;
}
.btn-navbar.btn--no-border:focus-visible {
  background-color: var(--gc-btn-focus-background);
  color: var(--gc-btn-focus-text);
  fill: var(--gc-btn-focus-text);
  box-shadow: var(--op-input-focus-primary);
}

.btn-success {
  background-color: var(--op-color-primary-plus-one);
  color: var(--op-color-primary-on-plus-one);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-plus-one);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-one);
}
.btn-success:hover {
  background-color: var(--op-color-primary-minus-one);
  color: var(--op-color-primary-on-minus-one);
  box-shadow: inset 0 0 0 1px var(--op-color-primary-minus-one);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-one);
}
.btn-success:focus, .btn-success:focus-within, .btn-success:focus-visible {
  background-color: var(--op-color-primary-base);
  color: var(--op-color-primary-on-base);
}

.btn-wrapper {
  justify-content: flex-start;
  display: flex;
  gap: calc(1rem * 1.2);
  gap: var(--op-space-small);
}
@media screen and (max-width: 768px) {
  .btn-wrapper {
    flex-direction: row;
    gap: calc(1rem * 1.6);
    gap: var(--op-space-medium);
  }
  .btn-wrapper .btn {
    align-self: flex-start;
  }
}

details > summary {
  list-style: none;
}
details > summary::marker {
  display: none;
}

.btn-see-previous {
  --_op-btn-font-large: var(--op-font-x-small);
  font-weight: 700;
  font-weight: var(--op-font-weight-bold);
  flex-direction: column;
  gap: calc(1rem * 1.2);
  gap: var(--op-space-small);
  align-items: flex-end;
  color: var(--gc-btn--no-border-text);
  padding: calc(1rem * 0.8);
  padding: var(--op-space-x-small);
  line-height: 1;
  line-height: var(--op-line-height-densest);
}
.btn-see-previous:hover {
  color: var(--gc-link-primary-color);
}
.btn-see-previous:hover path {
  fill: var(--gc-link-primary-color);
}
.btn-see-previous:focus-visible {
  background-color: var(--gc-btn-focus-background);
  color: var(--gc-btn-focus-text);
  box-shadow: var(--op-input-focus-primary);
}
.btn-see-previous:focus-visible path {
  fill: var(--gc-btn-focus-text);
}
.btn-see-previous.btn-see-previous--left {
  align-items: flex-start;
  margin-right: auto;
}

*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-family: "Noto Sans", "Noto Serif", sans-serif;
  font-family: var(--op-font-family);
}

.display-none {
  display: none;
}

button {
  border: 0;
  font-family: "Noto Sans", "Noto Serif", sans-serif;
  font-family: var(--op-font-family);
}

.panel {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 100%;
  color: var(--op-color-on-background);
  contain: inline-size;
  background-color: var(--op-color-neutral-plus-max);
  transition: left 300ms ease-in-out;
  border-radius: 0;
  border: 1px solid var(--op-color-border);
  box-shadow: 0 8px 12px hsl(0deg 0% 0% / 15%), 0 4px 4px hsl(0deg 0% 0% / 3%);
  box-shadow: var(--op-shadow-x-large);
}
.panel.panel--active {
  left: -1px;
}
.panel .header {
  display: flex;
  padding: calc(1rem * 2);
  padding: var(--op-space-large);
  padding-bottom: 0;
  font-size: calc(1rem * 2);
  font-size: var(--op-font-x-large);
}
.panel .body {
  box-shadow: none;
  padding: calc(1rem * 2);
  padding: var(--op-space-large);
  padding-block: calc(1rem * 1.2);
  padding-block: var(--op-space-small);
  display: flex;
  flex-direction: column;
  gap: calc(1rem * 1.6);
  gap: var(--op-space-medium);
}
.panel .footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(1rem * 2) calc(1rem * 2.4);
  padding: 0 var(--op-space-large) var(--op-space-x-large);
}

.date-select-wrapper {
  display: flex;
  justify-content: space-between;
  gap: calc(1rem * 0.8);
  gap: var(--op-space-x-small);
}
.date-select-wrapper .date-select {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.flatpickr__date-range {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 0;
  align-items: center;
  justify-content: space-between;
  padding: calc(1rem * 1.2);
  padding: var(--op-space-small);
  font-size: calc(1rem * 1.4);
  font-size: var(--op-font-small);
  font-weight: 600;
  font-weight: var(--op-font-weight-semi-bold);
  line-height: 1;
  line-height: var(--op-line-height-densest);
}
.flatpickr__date-range:not(.btn-primary) .flatpickr__date-range-value {
  color: var(--gc-text-meta);
}
.flatpickr__date-range:not(.btn-primary) .flatpickr__date-range-value.flatpickr__date-range-value--selected {
  color: var(--gc-link-primary-color);
}`;
export default styles;
