import { css, html } from 'lit'
import watchScreenSize from '../utilities/mobile-watch.js'
import DisconnectableLitElement from './disconnectable-lit-element.js'

export default class MobileDetails extends DisconnectableLitElement {
  static properties = {
    breakpoint: { type: Number, reflect: false },
    mobile: { type: Boolean, state: true },

    fullSiteHeader: { type: Boolean, reflect: true }
  }

  constructor() {
    super()
    this.breakpoint = 768
    this.fullSiteHeader = false
    this.mobile = false
  }

  connectedCallback() {
    super.connectedCallback()
    watchScreenSize(this)
  }

  render() {
    if (this.mobile) {
      return html`
        <details>
          <summary>
            <slot name="header"></slot>
          </summary>
          <slot></slot>
        </details>
      `
    }

    return html`
    ${this.fullSiteHeader
    ? html`
      <slot name="header"></slot>
    `
    : null}
    <slot></slot>
    `
  }

  static styles = css`
    summary::after {
      content: '\\e313';
      font-family: "Material Symbols Outlined";
      --op-mso-optical-sizing: 48;
      font-size: var(--op-font-x-large);
      justify-self: flex-end;
      transition: var(--op-transition-accordion);
      user-select: none;
    }

    summary {
      display: grid;
      min-height: var(--_op-accordion-summary-min-height);
      align-items: center;
      cursor: pointer;
      gap: var(--op-space-2x-small);
      grid-template-columns: auto 1fr auto;
      border-radius: var(--op-radius-medium);
      border: 1px solid var(--op-color-border);
      background: var(--op-color-neutral-plus-seven);
      padding: var(--op-space-x-small) var(--op-space-small);
    }
    summary::marker,
    summary::-webkit-details-marker {
      display: none;
      content: '';
    }

    details[open] summary {
      margin-bottom: var(--op-space-small);
    }
    details[open] summary::after {
      rotate: -0.5turn;
    }

    :host::header {
      font-weight: bold;
    }
  `
}
