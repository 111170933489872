import {css} from 'lit';
export const styles = css`*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-family: var(--op-font-family);
}

.display-none {
  display: none;
}

label {
  color: var(--op-color-neutral-on-plus-max);
  font-size: var(--gc-font-small-minus-one);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-base);
  cursor: pointer;
}

input {
  --__filter-control-option-height: var(--op-space-medium);
  --__filter-control-option-font-size: var(--op-font-x-small);
  width: var(--__filter-control-option-height);
  height: var(--__filter-control-option-height);
  align-self: center;
  margin: 0;
  accent-color: var(--op-color-primary-base);
  cursor: pointer;
}

.filter-control-option {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: var(--op-space-2x-small);
  gap: var(--op-space-2x-small);
  align-self: stretch;
  cursor: pointer;
}`;
export default styles;
