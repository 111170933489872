import {css} from 'lit';
export const styles = css`@import 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap';
:root {
  /**
  * @tokens Basic Colors
  * @presenter Color
  */
  --op-color-white: hsl(0deg 100% 100%);
  --op-color-black: hsl(0deg 0% 0%);
  /**
  * @tokens Primary Colors
  * @presenter Color
  */
  --op-color-primary-h: 216;
  --op-color-primary-s: 58%;
  --op-color-primary-l: 48%;
  /**
  * @tokens Neutral Colors
  * @presenter Color
  */
  --op-color-neutral-h: var(--op-color-primary-h);
  --op-color-neutral-s: 4%;
  --op-color-neutral-l: var(--op-color-primary-l);
  /**
  * @tokens Alerts Warning Colors
  * @presenter Color
  */
  --op-color-alerts-warning-h: 47;
  --op-color-alerts-warning-s: 100%;
  --op-color-alerts-warning-l: 61%;
  /**
  * @tokens Alerts Danger Colors
  * @presenter Color
  */
  --op-color-alerts-danger-h: 0;
  --op-color-alerts-danger-s: 99%;
  --op-color-alerts-danger-l: 76%;
  /**
  * @tokens Alerts Info Colors
  * @presenter Color
  */
  --op-color-alerts-info-h: 216;
  --op-color-alerts-info-s: 58%;
  --op-color-alerts-info-l: 48%;
  /**
  * @tokens Alerts Notice Colors
  * @presenter Color
  */
  --op-color-alerts-notice-h: 130;
  --op-color-alerts-notice-s: 61%;
  --op-color-alerts-notice-l: 64%;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --op-opacity-none: 0;
  --op-opacity-overlay: 0.2;
  --op-opacity-disabled: 0.4;
  --op-opacity-half: 0.5;
  --op-opacity-full: 1;
  /**
  * @tokens Breakpoints
  * @presenter Spacing
  */
  --op-breakpoint-x-small: 512px;
  --op-breakpoint-small: 768px;
  --op-breakpoint-medium: 1024px;
  --op-breakpoint-large: 1280px;
  --op-breakpoint-x-large: 1440px;
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --op-radius-small: 2px;
  --op-radius-medium: 4px;
  --op-radius-large: 8px;
  --op-radius-x-large: 12px;
  --op-radius-2x-large: 16px;
  --op-radius-circle: 50%;
  --op-radius-pill: 9999px;
  /**
  * @tokens Border Width
  * @presenter Spacing
  */
  --op-border-width: 1px;
  --op-border-width-large: 2px;
  --op-border-width-x-large: 4px;
  /**
  * @tokens Border Color
  * @presenter Color
  */
  --op-color-border: var(--op-color-neutral-plus-five);
  /**
  * @tokens Border Shadow
  * @presenter Shadow
  */
  --op-border-none: 0 0 0 0;
  --op-border-all: 0 0 0 var(--op-border-width);
  --op-border-top: 0 calc(-1 * var(--op-border-width)) 0 0;
  --op-border-right: var(--op-border-width) 0 0 0;
  --op-border-bottom: 0 var(--op-border-width) 0 0;
  --op-border-left: calc(-1 * var(--op-border-width)) 0 0 0;
  --op-border-y: var(--op-border-top) var(--op-color-border), var(--op-border-bottom) var(--op-color-border);
  --op-border-x: var(--op-border-left) var(--op-color-border), var(--op-border-right) var(--op-color-border);
  /**
  * @tokens Font Scale
  * @presenter FontSize
  */
  --op-font-scale-unit: 1rem;
  --op-font-2x-small: calc(var(--op-font-scale-unit) * 1);
  --op-font-x-small: calc(var(--op-font-scale-unit) * 1.2);
  --op-font-small: calc(var(--op-font-scale-unit) * 1.4);
  --op-font-medium: calc(var(--op-font-scale-unit) * 1.6);
  --op-font-large: calc(var(--op-font-scale-unit) * 1.8);
  --op-font-x-large: calc(var(--op-font-scale-unit) * 2);
  --op-font-2x-large: calc(var(--op-font-scale-unit) * 2.4);
  --op-font-3x-large: calc(var(--op-font-scale-unit) * 2.8);
  --op-font-4x-large: calc(var(--op-font-scale-unit) * 3.2);
  --op-font-5x-large: calc(var(--op-font-scale-unit) * 3.6);
  --op-font-6x-large: calc(var(--op-font-scale-unit) * 4.8);
  /**
  * @tokens Font Weight
  * @presenter FontWeight
  */
  --op-font-weight-thin: 100;
  --op-font-weight-extra-light: 200;
  --op-font-weight-light: 300;
  --op-font-weight-normal: 400;
  --op-font-weight-medium: 500;
  --op-font-weight-semi-bold: 600;
  --op-font-weight-bold: 700;
  --op-font-weight-extra-bold: 800;
  --op-font-weight-black: 900;
  /**
  * @tokens Font Family
  * @presenter FontFamily
  */
  --op-font-family: "Noto Sans", "Noto Serif", sans-serif;
  /**
  * @tokens Line Height
  * @presenter LineHeight
  */
  --op-line-height-none: 0;
  --op-line-height-densest: 1;
  --op-line-height-denser: 1.15;
  --op-line-height-dense: 1.3;
  --op-line-height-base: 1.5;
  --op-line-height-loose: 1.6;
  --op-line-height-looser: 1.7;
  --op-line-height-loosest: 1.8;
  /**
  * @tokens Letter Spacing
  * @presenter LetterSpacing
  */
  --op-letter-spacing-navigation: 0.01rem;
  --op-letter-spacing-label: 0.04rem;
  /**
  * @tokens Transition
  */
  --op-transition-accordion: rotate 120ms ease-in;
  --op-transition-input: all 120ms ease-in;
  --op-transition-sidebar: all 200ms ease-in-out;
  --op-transition-modal: all 300ms ease-in;
  --op-transition-panel: right 400ms ease-in;
  --op-transition-tooltip: all 300ms ease-in 300ms;
  /**
  * @tokens Animation
  * @presenter Animation
  */
  --op-animation-flash: rm-slide-in-out-flash 5s normal forwards;
  /**
  * @tokens Encoded Images
  */
  --op-encoded-images-dropdown-arrow-width: calc(1.2 * var(--op-space-scale-unit));
  --op-encoded-images-dropdown-arrow: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02IDguMzc1MDFMMCAyLjM3NTAxTDEuNCAwLjk3NTAwNkw2IDUuNTc1MDFMMTAuNiAwLjk3NTAwNkwxMiAyLjM3NTAxTDYgOC4zNzUwMVoiIGZpbGw9IiMwQTBBMEIiLz4KPC9zdmc+Cg==");
  /**
  * @tokens Size Scale
  * @presenter Spacing
  */
  --op-size-unit: 0.4rem;
  /**
  * @tokens Space Scale
  * @presenter Spacing
  */
  --op-space-scale-unit: 1rem;
  --op-space-3x-small: calc(var(--op-space-scale-unit) * 0.2);
  --op-space-2x-small: calc(var(--op-space-scale-unit) * 0.4);
  --op-space-x-small: calc(var(--op-space-scale-unit) * 0.8);
  --op-space-small: calc(var(--op-space-scale-unit) * 1.2);
  --op-space-medium: calc(var(--op-space-scale-unit) * 1.6);
  --op-space-large: calc(var(--op-space-scale-unit) * 2);
  --op-space-x-large: calc(var(--op-space-scale-unit) * 2.4);
  --op-space-2x-large: calc(var(--op-space-scale-unit) * 2.8);
  --op-space-3x-large: calc(var(--op-space-scale-unit) * 4);
  --op-space-4x-large: calc(var(--op-space-scale-unit) * 8);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --op-shadow-x-small: 0 1px 2px hsl(0deg 0% 0% / 3%), 0 1px 3px hsl(0deg 0% 0% / 15%);
  --op-shadow-small: 0 1px 2px hsl(0deg 0% 0% / 3%), 0 2px 6px hsl(0deg 0% 0% / 15%);
  --op-shadow-medium: 0 4px 8px hsl(0deg 0% 0% / 15%), 0 1px 3px hsl(0deg 0% 0% / 3%);
  --op-shadow-large: 0 6px 10px hsl(0deg 0% 0% / 15%), 0 2px 3px hsl(0deg 0% 0% / 3%);
  --op-shadow-x-large: 0 8px 12px hsl(0deg 0% 0% / 15%), 0 4px 4px hsl(0deg 0% 0% / 3%);
  /**
  * @tokens Z-Index
  */
  --op-z-index-header: 500;
  --op-z-index-footer: 500;
  --op-z-index-drawer: 700;
  --op-z-index-dialog: 800;
  --op-z-index-dialog-backdrop: 801;
  --op-z-index-dialog-content: 802;
  --op-z-index-dropdown: 900;
  --op-z-index-alert-group: 950;
  --op-z-index-tooltip: 1000;
  /**
  * @tokens Input Heights
  * @presenter Spacing
  */
  --op-input-height-small: 2.8rem;
  --op-input-height-medium: 3.6rem;
  --op-input-height-large: 4rem;
  --op-input-height-x-large: 8.4rem;
  /**
  * @tokens Input Focus
  * @presenter Shadow
  */
  --op-input-inner-focus: inset 0 0 0 var(--op-border-width-large);
  --op-input-outer-focus: 0 0 0 var(--op-border-width-x-large);
  --op-input-focus-primary: var(--op-input-inner-focus) var(--op-color-primary-plus-two),
    var(--op-input-outer-focus) var(--op-color-primary-plus-five);
  --op-input-focus-neutral: var(--op-input-inner-focus) var(--op-color-neutral-plus-two),
    var(--op-input-outer-focus) var(--op-color-neutral-plus-five);
  --op-input-focus-danger: var(--op-input-inner-focus) var(--op-color-alerts-danger-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-danger-plus-five);
  --op-input-focus-warning: var(--op-input-inner-focus) var(--op-color-alerts-warning-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-warning-plus-five);
  --op-input-focus-info: var(--op-input-inner-focus) var(--op-color-alerts-info-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-info-plus-five);
  --op-input-focus-notice: var(--op-input-inner-focus) var(--op-color-alerts-notice-plus-two),
    var(--op-input-outer-focus) var(--op-color-alerts-notice-plus-five);
}

@keyframes rm-slide-in-out-flash {
  0% {
    right: -100%;
  }
  10% {
    right: calc(1rem * 2);
    right: var(--op-space-large);
  }
  70% {
    right: calc(1rem * 2);
    right: var(--op-space-large);
  }
  100% {
    right: -100%;
  }
}
.modal-wrapper {
  --_op-modal-backdrop-active-opacity: var(--op-opacity-half);
  position: fixed;
  z-index: 800;
  z-index: var(--op-z-index-dialog);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  inset: 0;
  outline: 0;
  visibility: hidden;
}
.modal-wrapper .modal-wrapper__backdrop {
  position: fixed;
  z-index: 801;
  z-index: var(--op-z-index-dialog-backdrop);
  background: hsl(0deg 0% 0%);
  background: var(--op-color-black);
  inset: 0;
  opacity: 0;
  opacity: var(--op-opacity-none);
  transition: all 300ms ease-in;
  transition: var(--op-transition-modal);
  visibility: hidden;
}
.modal-wrapper--active.modal-wrapper {
  visibility: visible;
}
.modal-wrapper--active.modal-wrapper .modal {
  opacity: 1;
  opacity: var(--op-opacity-full);
  transform: scale(1);
}
.modal-wrapper--active.modal-wrapper .modal-wrapper__backdrop {
  opacity: var(--_op-modal-backdrop-active-opacity);
  visibility: visible;
}

.modal {
  --_op-modal-width: calc(141 * var(--op-size-unit));
  --_op-modal-max-height: calc(125 * var(--op-size-unit));
  z-index: 802;
  z-index: var(--op-z-index-dialog-content);
  width: calc(141 * 0.4rem);
  width: var(--_op-modal-width);
  border-radius: 4px;
  border-radius: var(--op-radius-medium);
  background-color: var(--op-color-background);
  box-shadow: 0 0 0 1px var(--op-color-border);
  box-shadow: var(--op-border-all) var(--op-color-border);
  color: var(--op-color-on-background);
  contain: paint;
  font-size: calc(1rem * 1.6);
  font-size: var(--op-font-medium);
  line-height: 1.5;
  line-height: var(--op-line-height-base);
  opacity: 0;
  opacity: var(--op-opacity-none);
  transform: scale(0.7);
  transition: all 300ms ease-in;
  transition: var(--op-transition-modal);
}
.modal .modal__header,
.modal .modal__body,
.modal .modal__footer {
  padding: calc(1rem * 1.6);
  padding: var(--op-space-medium);
}
.modal .modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(1rem * 1.8);
  font-size: var(--op-font-large);
  font-weight: 600;
  font-weight: var(--op-font-weight-semi-bold);
}
.modal .modal__body {
  max-height: var(--_op-modal-max-height);
  box-shadow: 0 0 0 1px var(--op-color-border);
  box-shadow: var(--op-border-all) var(--op-color-border);
  overflow-y: auto;
}
.modal .modal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc(1rem * 1.2);
  gap: var(--op-space-small);
}

@keyframes show-backdrop {
  from {
    opacity: 0;
    opacity: var(--op-opacity-none);
  }
  to {
    opacity: var(--_op-modal-backdrop-active-opacity);
  }
}
@keyframes hide-backdrop {
  from {
    opacity: var(--_op-modal-backdrop-active-opacity);
  }
  to {
    opacity: 0;
    opacity: var(--op-opacity-none);
  }
}
dialog.modal {
  position: fixed;
  display: block;
  padding: 0;
  border: none;
  inset: 0;
}
dialog.modal::backdrop {
  --op-color-black: hsl(0deg 0% 0%);
  --_op-modal-backdrop-active-opacity: 0.5;
  --op-opacity-none: 0;
  --op-opacity-full: 1;
  animation: show-backdrop 300ms ease-in;
  background: hsl(0deg 0% 0%);
  background: var(--op-color-black);
  opacity: 0.5;
  opacity: var(--_op-modal-backdrop-active-opacity);
}
dialog.modal[open] {
  opacity: 1;
  opacity: var(--op-opacity-full);
  transform: scale(1);
}
dialog.modal.modal--closing {
  opacity: 0;
  opacity: var(--op-opacity-none);
  transform: scale(0.7);
}
dialog.modal.modal--closing::backdrop {
  animation: hide-backdrop 300ms ease-in;
  opacity: 0;
  opacity: var(--op-opacity-none);
}

*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-family: "Noto Sans", "Noto Serif", sans-serif;
  font-family: var(--op-font-family);
}

.display-none {
  display: none;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.modal-wrapper {
  padding: 0;
  align-items: self-end;
  padding-inline: 0;
  transition: transform 300ms ease-in-out;
  transform: translateY(100%);
  opacity: 1;
  visibility: visible;
}
.modal-wrapper.modal-wrapper--active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.modal-wrapper.modal-wrapper--active .modal-wrapper__backdrop {
  opacity: 0;
}
.modal-wrapper .modal {
  transition: none;
  contain: inline-size;
  background-color: var(--op-color-neutral-plus-max);
  opacity: 1;
  transform: scale(1);
  border-radius: 0;
  border: 1px solid var(--op-color-border);
  box-shadow: 0 8px 12px hsl(0deg 0% 0% / 15%), 0 4px 4px hsl(0deg 0% 0% / 3%);
  box-shadow: var(--op-shadow-x-large);
}
.modal-wrapper .modal .modal__header {
  padding: calc(1rem * 2);
  padding: var(--op-space-large);
  padding-bottom: 0;
  font-size: calc(1rem * 2);
  font-size: var(--op-font-x-large);
}
.modal-wrapper .modal .modal__body {
  --_op-modal-max-height: 700px;
  box-shadow: none;
  padding: calc(1rem * 2);
  padding: var(--op-space-large);
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: calc(1rem * 1.6);
  gap: var(--op-space-medium);
}
.modal-wrapper .modal .modal__footer {
  padding: 0 calc(1rem * 2) calc(1rem * 2.4);
  padding: 0 var(--op-space-large) var(--op-space-x-large);
  align-items: center;
  justify-content: space-between;
  width: 100%;
}`;
export default styles;
