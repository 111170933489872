import { LitElement } from 'lit'

export default class DisconnectableLitElement extends LitElement {
  connectedCallback() {
    this._lifecycle_events = {}
    super.connectedCallback()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    if (this._lifecycle_events.disconnected) {
      this._lifecycle_events.disconnected.forEach((listener) => {
        listener()
      })
    }
  }

  registerLifecycleEvent(name, listener) {
    this._lifecycle_events[name] ||= []
    this._lifecycle_events[name].push(listener)
  }
}
