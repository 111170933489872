import { Controller } from '@hotwired/stimulus'
import { visit } from '@hotwired/turbo'

export default class extends Controller {
  updatePerPage(e) {
    const form = this.element.closest('form.simple_form')
    const query = new URLSearchParams(window.location.search)

    if (form) {
      const formData = new FormData(form)
      formData.forEach((formValue, key) => query.append(key, formValue))
    }

    query.set('per_page', e.target.value)
    visit(`${window.location.pathname}?${query.toString()}`)

    // TODO: The below commented will return a turbo stream but won't update the history :/
    /*
    Turbo.visit(
      `${window.location.pathname}?${query.toString()}`,
      { action: 'advance', acceptsStreamResponse: true }
    )
    */
  }
}
