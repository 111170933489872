import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  updateInput({ params: { type, value } }) {
    const hiddenInput = document.querySelector(`[name*="${type}"]`)

    if (hiddenInput.tagName.toLowerCase() === 'filter-control') {
      hiddenInput.select(value)
    } else {
      hiddenInput.value = value
      hiddenInput.dispatchEvent(new Event('change'))
    }
  }

  clear() {
    this.inputTarget.value = ''
    this.inputTarget.dispatchEvent(new Event('input'))
  }
}
