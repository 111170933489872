import {css} from 'lit';
export const styles = css`sl-dropdown {
  --sl-z-index-dropdown: calc(var(--op-z-index-header) - 1);
}
sl-dropdown:not([open]) .menu {
  display: none;
}

sl-dropdown.sl-dropdown--full-width {
  container-type: inline-size;
  width: 100%;
}
sl-dropdown.sl-dropdown--full-width sl-menu, sl-dropdown.sl-dropdown--full-width gc-menu {
  width: 100cqw;
}
sl-dropdown.sl-dropdown--full-width sl-menu sl-menu-item, sl-dropdown.sl-dropdown--full-width sl-menu gc-menu-item, sl-dropdown.sl-dropdown--full-width gc-menu sl-menu-item, sl-dropdown.sl-dropdown--full-width gc-menu gc-menu-item {
  width: 100%;
}

*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-family: var(--op-font-family);
}

.display-none {
  display: none;
}

.divider {
  --__filter-control-divider-padding: var(--op-space-x-small);
  --__filter-control-divider-height: 1px;
  width: 100%;
  height: 1px;
  height: var(--__filter-control-divider-height);
  background-color: var(--op-color-neutral-plus-four);
}

.menu {
  --_filter-control-min-width: 300px;
  --_filter-control-max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--op-color-neutral-on-plus-max);
  background: var(--op-color-neutral-plus-max);
  width: 300px;
  width: var(--_filter-control-min-width);
  border-radius: var(--gc-radius-default);
  border: 1px solid var(--op-color-border);
  box-shadow: var(--op-shadow-small);
}
.menu .menu-list {
  padding: var(--op-space-small) 0px;
  max-height: var(--_filter-control-max-height);
  overflow-y: auto;
  width: 100%;
}
.menu .menu-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 var(--op-space-small);
  gap: var(--op-space-small);
}
.menu .menu-item {
  display: flex;
  align-items: center;
  gap: var(--op-space-2x-small);
  align-self: stretch;
}

.input-wrapper {
  display: flex;
  padding: var(--op-space-x-small);
  padding-bottom: var(--op-space-3x-small);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--op-space-x-small);
  align-self: stretch;
}

sl-dropdown {
  width: 100%;
}`;
export default styles;
