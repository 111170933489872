import { LitElement, html } from 'lit'
import modalStyles from '../../assets/stylesheets/components/lit/modal.scss'

export default class OpticsModal extends LitElement {
  static properties = {
    opened: { type: Boolean, reflect: true }
  }

  constructor() {
    super()
    this.opened = false
  }

  connectedCallback() {
    super.connectedCallback()
  }

  render() {
    const openClass = this.opened ? 'modal-wrapper--active' : ''

    return html`
      <div class="modal-wrapper no-print ${openClass}">
        <div class="modal-wrapper__backdrop" @click=${this.close}></div>
        <div class="modal">
          <slot name="modal-header" class="modal__header"></slot>
          <slot name="modal-body" class="modal__body"></slot>
          <slot name="modal-footer" class="modal__footer"></slot>
        </div>
      </div>
    `
  }

  open() {
    this.opened = true
  }

  close() {
    this.opened = false
    this.dispatchEvent(new CustomEvent('closed'))
  }

  nextPanel() {
    this.panelIndex += 1
  }

  previousPanel() {
    this.panelIndex -= 1
  }

  static styles = [modalStyles]
}
