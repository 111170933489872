import { LitElement, html, css } from 'lit'

export default class MaterialIcon extends LitElement {
  static properties = {
    name: { type: String, reflect: true },
    size: { type: String, reflect: true },
    weight: { type: String, reflect: true }
  }

  render() {
    return html`
       <span class="material-symbols-outlined ${this.size} ${this.weight}" @click=${this.click}>${this.name}</span>
      `
  }

  static styles = css`
    .material-symbols-outlined {
      --op-mso-fill: 0;
      --op-mso-weight: var(--op-font-weight-normal);
      --op-mso-grade: 0;
      --op-mso-optical-sizing: 20;

      font-size: var(--op-font-medium);
      font-variation-settings: "FILL" var(--op-mso-fill), "wght" var(--op-mso-weight), "GRAD" var(--op-mso-grade),
        "opsz" var(--op-mso-optical-sizing);
      line-height: var(--op-line-height-densest);
      vertical-align: middle;
      font-family: 'Material Symbols Outlined';
      font-weight: normal;
      font-style: normal;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: 'liga';
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }

    // Weight
    .light {
      --op-mso-weight: var(--op-font-weight-light);
    }

    .normal {
      --op-mso-weight: var(--op-font-weight-normal);
    }

    .semi-bold {
      --op-mso-weight: var(--op-font-weight-semi-bold);
    }

    .bold {
      --op-mso-weight: var(--op-font-weight-bold);
    }

    // Size
    .medium {
      font-size: var(--op-font-medium);

      --op-mso-optical-sizing: 20;
    }

    .large {
      font-size: var(--op-font-large);

      --op-mso-optical-sizing: 40;
    }

    .x-large {
      font-size: var(--op-font-2x-large);

      --op-mso-optical-sizing: 48;
    }
  `
}
