import DateFilterControl from './date-filter-control.js'
import FilterControl from './filter-control.js'
import FilterControlOption from './filter-control-option.js'
import MaterialIcon from './material-icon.js'
import MobileDetails from './mobile-details.js'
import ModalFiltersWrapper from './modal-filters-wrapper.js'
import OpticsModal from './optics-modal.js'
import GcMenuItem from './shoelace/gc-menu-item.js'

customElements.define('date-filter-control', DateFilterControl)
customElements.define('filter-control', FilterControl)
customElements.define('filter-control-option', FilterControlOption)
customElements.define('material-icon', MaterialIcon)
customElements.define('mobile-details', MobileDetails)
customElements.define('optics-modal', OpticsModal)
customElements.define('modal-filters-wrapper', ModalFiltersWrapper)
customElements.define('gc-menu-item', GcMenuItem)
