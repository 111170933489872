import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'input']

  open() {
    this.dropdownTarget.open = true
  }

  // Shoelace looks for the space event as a trigger for the dropdown, but we want the input to receive it
  stop(event) {
    event.stopPropagation()
  }

  selectItem() {
    const menuItems = [...this.dropdownTarget.querySelectorAll('gc-menu-item')]
    const textSearchItem = menuItems.find((item) => item.textContent?.includes('Reports, Comments, and Credit Scores'))

    if (textSearchItem) {
      textSearchItem.click()
    } else if (menuItems.length === 1) {
      menuItems[0].click()
    }
  }
}
