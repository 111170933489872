import { Controller } from '@hotwired/stimulus'
import { visit } from '@hotwired/turbo'

export default class extends Controller {
  submit() {
    this.element.requestSubmit()
  }

  updateSort(event) {
    event.preventDefault()
    const form = this.element
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const sortValue = form.querySelector("select[name='sort']").value
    params.set('sort', sortValue)

    visit(`${url.pathname}?${params.toString()}`, { action: 'replace' })
  }
}
