export default function watchScreenSize(componentThis) {
  const setScreenSize = () => {
    componentThis.mobile = document.body.clientWidth <= componentThis.breakpoint
  }

  setScreenSize()
  window.addEventListener('resize', setScreenSize)

  componentThis.registerLifecycleEvent('disconnected', () => {
    window.removeEventListener('resize', setScreenSize)
  })
}
