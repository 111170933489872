import { html } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { get } from '@rails/request.js'
import filterControlStyles from '../../assets/stylesheets/components/lit/filter_control.scss'
import watchScreenSize from '../utilities/mobile-watch.js'
import DisconnectableLitElement from './disconnectable-lit-element.js'

export default class FilterControl extends DisconnectableLitElement {
  static formAssociated = true

  static properties = {
    breakpoint: { type: Number, reflect: false },
    mobile: { type: Boolean, state: true },

    collection: { type: Array, reflect: true },
    searchUrl: { type: String, reflect: true },
    searchParam: { type: String, reflect: true },
    selected: { type: Array, reflect: true },
    name: { type: String, reflect: true },
    id: { type: String, reflect: true },
    options: { type: Array, reflect: false },
    query: { type: String, reflect: true }
  }

  constructor() {
    super()
    this.breakpoint = 512
    this.mobile = false

    this.internals_ = this.attachInternals()
    this.collection = []
    this.searchParam = 'query'
    this.selected = []
    this.options = []
    this.query = ''
  }

  connectedCallback() {
    super.connectedCallback()

    this.options = this.collection
    watchScreenSize(this)
    this._updateFormValue()
    this.fetchOptions()
  }

  requestUpdate(name, oldValue) {
    if (name && name === 'query' && this.query !== oldValue) {
      this.fetchOptions()
    } else if (name && name === 'selected' && this.selected !== oldValue) {
      this._updateFormValue()
    }
    return super.requestUpdate(name, oldValue)
  }

  render() {
    let searchInput = ''
    let selectedOptions = ''

    if (this.searchUrl) {
      searchInput = html`
        <div class="input-wrapper">
          <slot name="input" @input="${this._changeQuery}" @change=${this._preventDefault}></slot>
        </div>`

      selectedOptions = html`${repeat(this.selected, (option) => option.value, (option, _index) => html`
        <filter-control-option
          class="menu-item"
          .value=${option.value}
          .name=${option.name}
          .checked=${true}
          @change=${this._optionChanged}
        />`)}`
      if (this.selected.length) {
        selectedOptions = html`${selectedOptions} <div class="divider"></div>`
      }
    }

    return html`
      <sl-dropdown .hoist=${this.mobile} stay-open-on-select>
        <div slot="trigger">
          <slot name="trigger"></slot>
        </div>

        <div class="menu">
          ${searchInput}

          <div class="menu-list">
            <div class="menu-items">
              ${selectedOptions}

              ${repeat(this.options, (option) => option.value, (option, _index) => html`
                <filter-control-option
                  class="menu-item"
                  .value=${option.value}
                  .name=${option.name}
                  .checked=${!!this.selected.find((s) => s.value === option.value)}
                  @change=${this._optionChanged}
                />`)}
            </div>
          </div>
        </div>
      </sl-dropdown>
      `
  }

  select(value) {
    const alreadySelectedOption = this.selected.find((s) => s.value === value)
    if (!alreadySelectedOption) {
      const option = this.options.find((o) => o.value === value || o.name === value)
      this.selected = [...this.selected, option]
    }
  }

  unselect(value) {
    this.selected = this.selected.filter((s) => s.value !== value && s.name !== value)
  }

  clear() {
    this.selected = []
  }

  async fetchOptions() {
    if (!this.searchUrl) {
      return
    }

    const request = await get(this.searchUrl, {
      query: {
        [this.searchParam]: this.query
      },
      responseKind: 'json'
    })
    this.options = await request.json
  }

  label() {
    if (this.selected.length === 0) {
      return ''
    }
    if (this.selected.length === 1) {
      return this.selected[0].name
    }

    return `${this.selected[0].name} +${this.selected.length - 1}`
  }

  _updateFormValue() {
    const newFormData = new FormData()

    newFormData.append(this.name, '')
    this.selected.forEach((option) => {
      newFormData.append(this.name, option.value)
    })
    this.internals_.setFormValue(newFormData)
    this._dispatchChange()
  }

  // Events

  _changeQuery(event) {
    this.query = event.target.value
  }

  _dispatchChange() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: {
        selected: this.selected.map((select) => select.value),
        label: this.label(),
        id: this.id
      }
    }))
  }

  _preventDefault(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  _optionChanged(event) {
    if (event.detail.checked) {
      this.select(event.detail.value)
    } else {
      this.unselect(event.detail.value)
    }
  }

  static styles = [filterControlStyles]
}
