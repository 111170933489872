import { html } from 'lit'
import { ref, createRef } from 'lit/directives/ref.js'
import flatpickr from 'flatpickr'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import dateFilterControlStyles from '../../assets/stylesheets/components/lit/date_filter_control.scss'
import watchScreenSize from '../utilities/mobile-watch.js'
import DisconnectableLitElement from './disconnectable-lit-element.js'

export default class DateFilterControl extends DisconnectableLitElement {
  static formAssociated = true
  inputRef = createRef()

  static properties = {
    breakpoint: { type: Number, reflect: false },
    mobile: { type: Boolean, state: true },
    value: { type: String, reflect: false },
    name: { type: String, reflect: true },
    id: { type: String, reflect: true },
    maxDate: { type: String },
    minDate: { type: String },
    inline: { type: Boolean, state: true },
    opened: { type: Boolean, state: true },

    activeSelection: { type: String, state: true },
    startSelection: { type: Date, state: true },
    endSelection: { type: Date, state: true }
  }

  constructor() {
    super()
    this.breakpoint = 512
    this.mobile = false

    this.internals_ = this.attachInternals()
    this.value = ''
    this.inline = false
    this.opened = false

    this.activeSelection = 'start'
    this.startSelection = null
    this.endSelection = null
  }

  // Lifecycle methods

  connectedCallback() {
    super.connectedCallback()
    this._updateFormValue()
    watchScreenSize(this)
  }

  disconnectedCallback() {
    this._flatpickr.destroy()
    super.disconnectedCallback()
  }

  updated(changedProperties) {
    if (changedProperties.has('inline') || changedProperties.has('opened')) {
      this._initializeFlatpickr()
    }

    return super.updated(changedProperties)
  }

  requestUpdate(name, oldValue) {
    if (name && name === 'value' && this.value !== oldValue) {
      this._onValueChange()
    } else if (name && name === 'mobile' && this.mobile !== oldValue) {
      this.inline = this.mobile
    }
    return super.requestUpdate(name, oldValue)
  }

  render() {
    if (this.inline) {
      const openedClass = this.opened ? 'panel--active' : ''

      return html`
        <slot name="toggle" @click=${this._openFlatpickr}></slot>

        <div class="panel ${openedClass}">
          <div class="header">
            <slot name="panel-back" @click=${this._closeFlatpickr}></slot>
          </div>
          <div class="body">
            <div class="date-select-wrapper">
              ${this._dateSelectButton('Start', this.activeSelection, this.startSelection, this._setStartDate)}
              ${this._dateSelectButton('End', this.activeSelection, this.endSelection, this._setEndDate)}
            </div>

            <input ${ref(this.inputRef)} class="display-none" .value=${this.value} @change=${this._changeValue}>
          </div>
          <div class="footer">
            <slot name="clear-button" @click=${this.clear}></slot>
            <slot name="close-panel" @click=${this._closeFlatpickr}></slot>
          </div>
        </div>
        `
    }

    return html`
       <div ${ref(this.inputRef)} .value=${this.value} @change=${this._changeValue}>
         <slot name="toggle" @click=${this._openFlatpickr}></slot>
       </div>
      `
  }

  // Public Methods

  clear() {
    this._flatpickr.clear()
  }

  _dateSelectButton(label, activeSelection, date, onClick) {
    const buttonClass = activeSelection === label ? 'btn-primary' : ''
    const selectionClass = date ? 'flatpickr__date-range-value--selected' : ''
    const selectionText = date ? this._flatpickr.formatDate(date, 'm/d/Y') : 'Select Date'

    return html`
      <button type="button" class="btn flatpickr__date-range ${buttonClass}" @click=${onClick}>
        <div class="date-select">
          <div>${label}</div>
          <div class="flatpickr__date-range-value ${selectionClass}">${selectionText}</div>
        </div>
        <material-icon name="close" size="x-large" weight="semi-bold"></material-icon>
      </button>
    `
  }

  _updateFormValue() {
    this.internals_.setFormValue(this.value)
  }

  // Events

  _onValueChange() {
    this._updateFormValue()
    this._dispatchChange()
    const selectedDates = this._flatpickr?.selectedDates

    if (!selectedDates) {
      return
    }

    this.activeSelection = 'Start'

    if (this.changing === 'start') {
      this.startSelection = null
    } else if (this.changing === 'end') {
      this.endSelection = null
    } else if (selectedDates.length === 0) {
      this.startSelection = null
      this.endSelection = null
    } else if (selectedDates.length === 1) {
      this.startSelection = selectedDates[0]
      this.endSelection = null
    } else {
      this.activeSelection = 'End'
      this.startSelection = selectedDates[0]
      this.endSelection = selectedDates[1]
    }
    this.changing = null
  }

  _dispatchChange() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: {
        value: this.value,
        label: this.value,
        id: this.id
      }
    }))
  }

  _changeValue(event) {
    this.value = event.target.value
  }

  // Private methods

  _setStartDate() {
    if (this._flatpickr.selectedDates.length > 1) {
      this.changing = 'start'
      this._flatpickr.setDate(this._flatpickr.selectedDates[1], true)
    } else {
      this._flatpickr.setDate(null, true)
    }
  }

  _setEndDate() {
    if (this._flatpickr.selectedDates.length === 1) {
      this.activeSelection = 'End'
      return
    }

    if (this._flatpickr.selectedDates.length > 1) {
      this.changing = 'end'
      this._flatpickr.setDate(this._flatpickr.selectedDates[0], true)
    }
  }

  _openFlatpickr() {
    if (this.inline) {
      this.opened = true
    } else {
      this._flatpickr.open()
    }
  }

  _closeFlatpickr() {
    if (this.inline) {
      this.opened = false
    } else {
      this._flatpickr.close()
    }
  }

  _icon(name) {
    if (this.inline) {
      return `<button type="button" class="btn btn--icon btn--small">
        <material-icon name="${name}"></material-icon>
      </button>`
    }

    return `<button type="button" class="btn btn--icon btn--small">
      <span class="material-symbols-outlined">${name}</span>
    </button>`
  }

  _initializeFlatpickr() {
    if (this._flatpickr) {
      this._flatpickr.destroy()
    }

    if (this.inputRef.value) {
      this._flatpickr = flatpickr(this.inputRef.value, this._config())

      if (!this.inline) {
        this._flatpickr.changeMonth(-1)
      }
    }
  }

  _config() {
    return {
      mode: 'range',
      inline: this.inline,
      showMonths: this.inline ? 1 : 2,
      maxDate: this.maxDate,
      minDate: this.minDate,

      nextArrow: this._icon('east'),
      prevArrow: this._icon('west'),
      closeOnSelect: false,
      locale: {
        weekdays: {
          shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        }
      },
      plugins: this._plugins()
    }
  }

  _plugins() {
    if (this.inline) {
      return []
    }
    return [
      ShortcutButtonsPlugin({
        button: [
          { label: 'Cancel', attributes: { class: 'btn', type: 'button' } },
          { label: 'Done', attributes: { class: 'btn-primary margin-left-xs', type: 'button' } }
        ],
        onClick(index, fp) {
          if (index === 0) {
            fp.clear()
          }
          fp.close()
        }
      })
    ]
  }

  static styles = [dateFilterControlStyles]
}
