import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'control']

  static values = {
    select: String
  }

  updateLabel(e) {
    const labelTarget = this.labelTargets.find((label) => label.dataset.filterLabelIdParam === e.detail.id)
    this._updateLabel(labelTarget, e.detail.label)
  }

  textInputUpdateLabel(e) {
    const labelTarget = this.labelTargets.find((label) => label.dataset.filterLabelIdParam === e.target.id)
    this._updateLabel(labelTarget, e.target.value)
  }

  _updateLabel(target, text) {
    target.innerText = text

    if (text) {
      target.parentElement.classList.remove('display-none')
    } else {
      target.parentElement.classList.add('display-none')
    }
  }

  clear({ params: { id } }) {
    this.controlTargets
      .filter((control) => control.id === id)
      .forEach((controlTarget) => {
        if (controlTarget.clear) {
          controlTarget.clear()
        } else {
          controlTarget.value = ''
          controlTarget.dispatchEvent(new Event('change'))
          const labelTarget = this.labelTargets.find((label) => label.dataset.filterLabelIdParam === id)
          labelTarget.parentElement.classList.add('display-none')
        }
      })
  }
}
