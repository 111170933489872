import { html } from 'lit'
import { ref, createRef } from 'lit/directives/ref.js'
import watchScreenSize from '../utilities/mobile-watch.js'
import DisconnectableLitElement from './disconnectable-lit-element.js'

export default class MobileFiltersWrapper extends DisconnectableLitElement {
  static properties = {
    breakpoint: { type: Number, reflect: false },
    mobile: { type: Boolean, state: true },
    modalOpened: { type: Boolean, state: true }
  }

  modal = createRef()

  constructor() {
    super()
    this.breakpoint = 512
    this.mobile = false
  }

  connectedCallback() {
    super.connectedCallback()
    watchScreenSize(this)
  }

  render() {
    if (this.mobile) {
      return html`
        <slot name="modal-toggle" @click=${this._openModal}></slot>

        <optics-modal ${ref(this.modal)} .opened=${this.modalOpened}>
          <div slot="modal-header">
            Filters
          </div>
          <div slot="modal-body">
            <slot name="filters"></slot>
          </div>
          <slot slot="modal-footer">
            <slot name="clear-filters"></slot>
            <slot name="close" @click=${this._closeModal}></slot>
          </slot>
        </optics-modal>
      `
    }

    return html`
      <slot name="filters"></slot>
    `
  }

  _openModal() {
    this.modal.value.open()
  }

  _closeModal() {
    this.modal.value.close()
  }
}
