import { LitElement, html } from 'lit'
import { ref, createRef } from 'lit/directives/ref.js'
import filterControlOptionStyles from '../../assets/stylesheets/components/lit/filter_control_option.scss'

export default class FilterControlOption extends LitElement {
  static properties = {
    checked: { type: Boolean, reflect: true },
    name: { type: String, reflect: true },
    value: { type: String, reflect: true }
  }

  wrapperRef = createRef()

  requestUpdate(name, oldValue) {
    if (name && name === 'checked' && this.checked !== oldValue) {
      this._dispatchChange()
    }
    return super.requestUpdate(name, oldValue)
  }

  render() {
    return html`
      <div ${ref(this.wrapperRef)} class="filter-control-option" @click="${this._divClicked}">
        <input
          type="checkbox"
          id="checkbox"
          class="form-control form-control--small"
          .checked=${this.checked}
          @change=${this._inputChanged}>
        <label for="checkbox">${this.name}</label>
      </div>
      `
  }

  // Events

  _dispatchChange() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: {
        checked: this.checked,
        value: this.value
      }
    }))
  }

  _inputChanged(event) {
    this.checked = event.target.checked
  }

  _divClicked(event) {
    if (event.target === this.wrapperRef.value) {
      this.checked = !this.checked
    }
  }

  static styles = [filterControlOptionStyles]
}
